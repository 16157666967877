import { throttle } from 'throttle-debounce';
import { isMobile, addActiveLinkClass } from '../../utils';


// Header handler
const header = document.querySelector('.Header');
const burger = header.querySelector('.Header__burger');

/**
 * Sticky header
 */
const stickyHeader = () => {
  if (!header) return;

  const headerHeight = header.offsetHeight / 2;
    const topOffset = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0); /* eslint-disable-line */

  if (topOffset > headerHeight) {
    header.classList.add('sticked');
  } else {
    header.classList.remove('sticked');
  }
};

/**
 * Toggle Menu
 */
const toggleMenu = () => {
  if (!header && !burger) return;

  burger.addEventListener('click', () => {
    if (header.classList.contains('open-menu')) {
      header.classList.remove('open-menu');
    } else {
      header.classList.add('open-menu');
    }
  }, false);
};

/**
 * Remove Open Menu Class
 * @type {wrapper}
 */
const removeOpenMenuClass = throttle(100, () => {
  if (!isMobile() && header.classList.contains('open-menu')) {
    header.classList.remove('open-menu');
  }
});

/**
 *  Animate order now btn
 */
// const animateOrderNowBtn = () => {
//   const btns = header.querySelectorAll('.order-now');
//   if (!btns) return;

//   const btnClass = 'order-now--animate';

//   [].slice.call(btns).forEach((btn) => {
//     btn.classList.add(btnClass);

//     setInterval(() => {
//       if (btn.classList.contains(btnClass)) {
//         btn.classList.remove(btnClass);
//       } else {
//         btn.classList.add(btnClass);
//       }
//     }, 800);
//   });
// };

function toggleSubmenu() {
  const subMenu = document.querySelectorAll('.submenu');
  if (subMenu.length > 0 && !isMobile()) return;

  [].slice.call(subMenu).forEach((menu) => {
    const subMenuTitle = menu.querySelector('.submenu__title');
    subMenuTitle.addEventListener('click', (e) => {
      e.preventDefault();
      menu.classList.toggle('submenu--active');
    });
  });
}

window.addEventListener('DOMContentLoaded', () => {
  toggleMenu();
  stickyHeader();
  toggleSubmenu();

  window.addEventListener('scroll', stickyHeader, false);
  window.addEventListener('resize', () => {
    throttle(100, stickyHeader);
    removeOpenMenuClass();
  }, false);

  // Add active class
  addActiveLinkClass({
    anchorSelector: '.Header__menu-link',
  });

  // detect Safari and ios
  const isiOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  if (isiOS) document.documentElement.classList.add('IOS');

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) document.documentElement.classList.add('Safari');
});
