/**
 *
 * @param inputArray
 * @param perChunk
 * @returns {*}
 */
const sliceArrayPerChunk = (
  inputArray = [],
  perChunk = 1,
) => inputArray.reduce((resultArray, item, index) => {
  const chunkIndex = Math.floor(index / perChunk);

  if (!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = []; // start a new chunk
  }

  resultArray[chunkIndex].push(item);

  return resultArray;
}, []);

export default sliceArrayPerChunk;
