import { fetch } from '../../utils';

document.addEventListener('DOMContentLoaded', () => {
  const paramsSearch = window.location.search;
  const urlParams = new URLSearchParams(paramsSearch);
  const refererWebmasterParamValue = urlParams.has('r') && urlParams.get('r');

  if (refererWebmasterParamValue) {
    const apiUrl = `/api/set_webmaster${paramsSearch}`;

    fetch(apiUrl, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
      credentials: 'include',
    })
      .catch(() => {});
  }
});
