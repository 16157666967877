export default async (url, options = {}) => {
  const {
    method = 'GET',
    headers,
    body = null,
    credentials = 'omit',
  } = options;

  try {
    const response = await fetch(url, {
      method,
      headers,
      body,
      credentials: credentials !== 'omit' ? 'include' : 'omit',
    });

    if (!response.ok) {
      throw new Error('Bad request');
    }

    return {
      json: () => response.json(),
      text: () => response.text(),
    };
  } catch (error) {
    throw new Error(`Request failed: ${error.message}`);
  }
};
